export function getEmailRules(required = true) {
    if (required) {
        return [
            v => !!v || 'E-mail moet ingevuld worden',
            v => /.+@.+\..+/.test(v) || 'E-mail moet juiste structuur bezitten',
            v => !/.+\s.+/.test(v) || 'Er mogen geen spaties in het email-adres ingegeven worden'
        ]
    }

    return [
        v => (!v || /.+@.+\..+/.test(v)) || 'E-mail moet juiste structuur bezitten',
        v => (!v || !/.+\s.+/.test(v)) || 'Er mogen geen spaties in het email-adres ingegeven worden'
    ]
}

export function getPasswordRules() {
    return [
        v => !!v || 'Wachtwoord moet ingevuld worden',
        v => !/.+\s.+/.test(v) || 'Er mogen geen spaties in het wachtwoord ingegeven worden'
    ]
}

export function getRequiredRules(message) {
    return [v => (!!v || v === 0) || message]
}

export function getStartsWithRules(prefix, message) {
    return [v => (!v || v.startsWith(prefix)) || message]
}

export function getNotEmptyArrayRules(message) {
    return [v => (!v || v.length > 0) || message]
}

export function getMaxStringSizeRules(maxSize, message) {
    return [v => (!v || v.length <= maxSize) || message]
}

export function getMinStringSizeRules(minSize, message) {
    return [v => (!v || v.length >= minSize) || message]
}

export function getBiggerThanZeroRules(message) {
    return [v => ((!v && v !== 0) || v > 0) || message]
}

export function getPositiveRules(message) {
    return [v => (!v || v > 0) || message]
}

export function getMinimalNumberRules(message, minimalNumber){
    return [v => (!v || v >= minimalNumber) || message]
}

export function checkRules(value, rules) {
    let error = rules?.length > 0;
    let messages = [];

    rules.forEach(rule => {
      let result = rule(value)
      //This makes sure the functionality of the rules is simular as Vuetify's
      //As described here: https://v2.vuetifyjs.com/en/api/v-input/#props-rules
      //A rule function can return a boolean or a string with an error message
      //In the case of a string, we want to turn the text field red and add the string to the error messages that should be displayed
      if (typeof result === 'string') {
        error = false;
        messages.push(result);
      } else if (result == true) {
        error = false;
      }
    });
    return {
        messages,
        error
    }
  }

export function getFileSizeRules(size, message) {
    return [value => (!!value && value.size < size) || message]
}